<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Sous-activité" />
          </div>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter une sous-activité</div>
              </v-row>
            </v-card-title>

            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom digital</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    dense
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le label -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">libellé</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import SubActivitiesService from "@/service/sfr/sub_activity_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import { trimString } from "@/tools/string_tool.js";


export default {
  name: "AddSubActivity",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**le service d'accès au APIs. */
      subActivitiesService: null,

      digitalName: null,
      label: null,

    };
  },
  methods: {
    async save() {
      try {
        let item = {};
        item.digitalName = this.digitalName;
        item.label = this.label;

        await this.subActivitiesService.create(item);

        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "ajouter : " + (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },
  },
  computed: {
    completed() {
      if (!this.digitalName || !this.label) return false;

      return true;
    },

    canSave() {
      return this.completed;
    },
  },
  mounted() {
    
    // Instanciation de la classe service
    this.subActivitiesService = new SubActivitiesService(this.$api);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);
  },
};
</script>

<style>
</style>